import React, { useState } from "react";
import { Modal } from "@mui/material";
import { checkAddress } from "../../utils/funcs";
import { InputContainer } from "../InputContainer";
import { defaultAddress, statesAndLgas } from "../../utils/mockData";

export const AddressModal = ({
  open,
  setOpen,
  brandColor = "#3E5EA9",
  addAddress,
}) => {
  const [error, setError] = useState(false);
  const [address, setAddress] = useState({ ...defaultAddress });

  const handleUpdateAddress = (e) => {
    setError(false);
    const value = e?.target?.value || "";
    const name = e?.target?.name || "";

    if (!name || name === "") return;
    if (name === "state") {
      setAddress((prev) => {
        return {
          ...prev,
          lga: "",
          [name]: value,
        };
      });
    } else {
      setAddress((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen()}>
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="w-[90%] md:w-[75%] lg:w-[40%] max-h-[90vh] bg-white rounded-md overflow-y-auto p-5">
          <div className=" space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-2xl">Enter your address</h3>
              <button
                onClick={() => setOpen()}
                className="text-sm font-bold text-red-500 cursor-pointer"
              >
                X
              </button>
            </div>
            <p className="text-sm border border-yellow-500 bg-yellow-50 font-semibold rounded-md p-4">
              NB: This address will be used to deliver any prescriptions(drugs)
              from the doctor.
            </p>
          </div>
          <div className="">
            <InputContainer label="Street" required>
              <input
                name="street"
                type="text"
                style={{outlineColor: brandColor}}
                placeholder="879 Dorthea Ford, festac"
                className={`input_variant_2 focus:outline focus:outline-2`}
                value={address.street}
                onChange={(e) => handleUpdateAddress(e)}
              />
            </InputContainer>
            <InputContainer label="State" required>
              <select
                name="state"
                style={{outlineColor: brandColor}}
                value={address.state}
                className="select-input heela-discomfort-level focus:outline focus:outline-2"
                onChange={(event) => {
                  handleUpdateAddress(event);
                }}
              >
                <option>Select State</option>
                {Object.keys(statesAndLgas).map((state) => {
                  return (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </InputContainer>
            <InputContainer label="LGA" required>
              <select
                name="lga"
                style={{outlineColor: brandColor}}
                value={address.lga}
                className="select-input heela-discomfort-level focus:outline focus:outline-2"
                onChange={(event) => {
                  handleUpdateAddress(event);
                }}
              >
                <option>Select LGA</option>
                {(statesAndLgas[address.state] || []).map((lga) => {
                  return (
                    <option key={lga} value={lga}>
                      {lga}
                    </option>
                  );
                })}
              </select>
            </InputContainer>
            {error && (
              <p className="text-sm text-red-500 font-medium">
                Please fill all fields.
              </p>
            )}
            <button
              onClick={() => {
                const isAddressCorrect = checkAddress(address);
                if (!isAddressCorrect) {
                  setError(true);
                  return;
                }
                addAddress(address);
                setOpen();
              }}
              className="button_variation_1"
              style={{ backgroundColor: brandColor }}
            >
                Add Address
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
