import * as yup from "yup";

export const principalHmoIdTestError = "IDs should not match";

export const symptomsSchema = yup.object().shape({
  name: yup.string().required(),
});

export const pharmacyFeatureSchema = yup.object({
  address: yup.string().required("Please add an address"),
  pharmacyCode: yup.string().nullable(),
  pharmacyName: yup.string().when("pharmacyCode", {
    is: (value) => value && value !== "",
    then: (schema) => schema.required("Please select a Pharmacy to continue."),
    otherwise: (schema) => schema.notRequired(),
  }),
  pharmacyAddress: yup.string().when("pharmacyCode", {
    is: (value) => value && value !== "",
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  isDelivery: yup.boolean().nullable(),
});

export const followUpSchema = yup.object({
  isFollowUp: yup.bool(),
  followUpConsultationId: yup.string().when("isFollowUp", {
    is: (value) => value && value === true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  doctor: yup.string().when("isFollowUp", {
    is: (value) => value && value === true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const hospitalSchema = yup.object({
  time: yup.string().required("Select a time for the consultation"),
  type: yup.string().required("Type of consultation is required"),
  doctor: yup.string().required("Doctor is required"),
});

export const mainSchema = yup.object({
  symptoms: yup
    .array()
    .of(symptomsSchema)
    .min(1, "Select at least one symptom.")
    .required("This field is required"),

  discomfortLevel: yup
    .string()
    .oneOf(
      ["None", "Mild", "Moderate", "Severe", "Intense"],
      "This field is required."
    )
    .required("Select a discomfort level."),

  firstNotice: yup
    .string()
    // .oneOf(
    //   ["A few days ago", "Last Week", "One Month ago", "Last Year"],
    //   "This field is required."
    // )
    .required("This field is required."), // Select an option to indicate when you first noticed it the above symptom(s).

  contactMedium: yup
    .string()
    .oneOf(["chat", "voice", "video"], "Select a contact medium.")
    .required("Select a contact medium."),

  gender: yup
    .string()
    .oneOf(["Male", "Female", "male", "female"], "Select a gender.")
    .required("Gender field is required."),

  description: yup.string(),
  image: yup.string().required(),
  hmoId: yup.string().required("HMO/Access ID is required."),
  lastName: yup.string().required("Last Name not found!"),
  firstName: yup.string().required("First Name not found!"),
  providerId: yup.string().required("Provider ID not found!"),
  phoneNumber: yup
    .string()
    .matches(/^(?:\+?234|0)?[789][01]\d{8}$/, "Invalid phone number")
    .required("Phone number is required"),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required."),

  dob: yup
    .date()
    .typeError("Select a Date to continue")
    .required("Please enter your Date of Birth (DOB)."),

  consultationOwner: yup
    .string()
    .oneOf(["Myself", "Dependant"], "Select a consultation owner.")
    .required("This field is required."),

  principalHmoId: yup.string().when("consultationOwner", {
    is: (value) => value === "Dependant",
    then: (schema) => schema.required("Principal's ID is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const generateFullSchema = (hasPharmacyFeature) => {
  const pharmacySchema = hasPharmacyFeature
    ? mainSchema.concat(pharmacyFeatureSchema)
    : mainSchema;
  return (hasFollowUpFeature) => {
    const mergedFollowUpSchema = hasFollowUpFeature
      ? pharmacySchema.concat(followUpSchema)
      : pharmacySchema;
    return (isHospitalConsultation) => {
      const finalSchema = isHospitalConsultation
        ? mergedFollowUpSchema.concat(hospitalSchema)
        : mergedFollowUpSchema;
      return finalSchema;
    };
  };
};

// export const generateFullSchema = (
//   hasPharmacyFeature,
//   hasFollowUpFeature,
//   isHospitalConsultation
// ) => {
//   if (hasFollowUpFeature && hasPharmacyFeature) {
//     return mainSchema.concat(followUpSchema).concat(pharmacyFeatureSchema);
//   }

//   if (hasPharmacyFeature && !hasFollowUpFeature) {
//     return mainSchema.concat(pharmacyFeatureSchema);
//   }

//   if (hasFollowUpFeature && !hasPharmacyFeature) {
//     return mainSchema.concat(followUpSchema);
//   }

//   return mainSchema;
// };
